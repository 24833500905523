import PropTypes from 'prop-types'
import Spinner from "../Spinner"

const Button = ({ title, onClick, loading, disabled }) => {
  return (
    <button className="main-btn" onClick={onClick} disabled={disabled || loading}>
      {loading ? <Spinner size='small' /> : title}
    </button>
  )
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  loading: false,
  disabled: false,
}

export default Button