import { useMemo } from "react"
import { ConnectionProvider, WalletProvider, } from "@solana/wallet-adapter-react"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import { GlowWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, } from "@solana/wallet-adapter-wallets"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"
import { SOLANA_RPC_URL } from './global.config.js'
import { ToastContainer } from 'react-toastify'

import { Routes, Route, Outlet } from "react-router-dom";

import Header from './components/Skeleton/Header'
import Footer from './components/Skeleton/Footer'
import PuffsterzStakingPage from "./pages/PuffsterzStaking"
import VibeTribeStakingPage from "./pages/VibeTribeStaking"
import NotFoundPage from "./pages/NotFound"

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PuffsterzStakingPage />} />
        <Route path='/vibe-tribe' element={<VibeTribeStakingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>

  );
}

function Layout() {
  const network = WalletAdapterNetwork.Mainnet

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={SOLANA_RPC_URL}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="main-container flex flex-col min-h-screen">
            <Header />
            <div className="container px-5 mx-auto lg:px-4 xl:px-0 pt-20">
              <Outlet />
            </div>
            <Footer />
          </div>
          <ToastContainer autoClose={5000} position="bottom-center" />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}