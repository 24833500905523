const Footer = () => {
  return (
    <footer className="py-10 mt-auto">
      <div className="container mx-auto">
        <div className=" bg-gradient-to-r from-[#1d1d1d] via-[#a9ffcc] to-[#1d1d1d] h-[5px] w-full rounded-xl mb-5"></div>

        <p className="text-white text-center text-base">
          &copy; {(new Date()).getFullYear()} <a className="text-blue-400" href="https://puffsterz.io" target='_blank' rel="noreferrer">Puffsterz Corp.</a>
        </p>
        <p className="text-white text-center text-base">
          All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
