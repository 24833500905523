function EmptyState({ title, description }) {
  return (
    <div className='text-center text-white'>
      <h4 className="text-lg font-semibold">{title}</h4>
      {description && <p className="text-sm mt-2">{description}</p>}
    </div>
  );
}

export default EmptyState;
