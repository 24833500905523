import Base from "./Base"

class Staking extends Base {
  async totalStakedAmount() {
    const data = await this.get('staking/total_staked')
    return data?.data
  }

  async staked({ walletAddress }) {
    const data = await this.get('staking/staked', { wallet_address: walletAddress })
    return data?.data
  }

  async stakingData({ walletAddress }) {
    const data = await this.get('staking/staking_data', { wallet_address: walletAddress })
    return data?.data
  }

  async getStakeTransactionHash({ walletAddress, mintAddresses }) {
    const data = await this.post('staking/stake', {
      wallet_address: walletAddress,
      mint_addresses: mintAddresses,
    })
    return data?.data
  }

  async getUnstakeTransactionHash({ mintAddresses }) {
    const data = await this.post('staking/unstake', { mint_addresses: mintAddresses })
    return data?.data
  }

  async getClaimRewardsHash({ walletAddress }) {
    const data = await this.get('staking/claim_rewards', { wallet_address: walletAddress })
    return data?.data
  }
}

const staking = new Staking()
export default staking
