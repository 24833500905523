import PropTypes from 'prop-types'
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from '@solana/wallet-adapter-react';

import EmptyState from "../EmptyState"
import Spinner from "../Spinner"
import NFTCard from "../NFTCard"
import { useMemo } from 'react';

const NFTListing = ({ title, items, stakeNft, unstakeNft, emtpyTitle, isLoading, isProcessingMassTransactions, getColorForNfts }) => {
  const { publicKey } = useWallet()

  const borderColor = useMemo(() => {
    return getColorForNfts(items)
  }, [items])

  const Body = () => {
    if (isLoading) {
      return (
        <div className='flex items-center justify-center'>
          <Spinner />
        </div>
      )
    } else if (!publicKey) {
      return (
        <div className='flex items-center justify-center gap-2'>
          <h3 className='text-white'>Connect your wallet:</h3>
          <WalletMultiButton className='wallet-adapter-button wallet-adapter-button-trigger !py-2 !px-2 !h-auto !text-sm' />
        </div>
      )
    } else if (items.length > 0) {
      return (
        <div className='scrollbar-thin scrollbar-thumb-[#a9ffcc] scrollbar-track-border scrollbar-track-transparent scrollbar-thumb-rounded max-h-[70vh] overflow-y-auto'>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
            {items.map(item => (
              <NFTCard
                key={`${item.mint}_${title}`}
                item={item}
                stakeNft={stakeNft}
                unstakeNft={unstakeNft}
                isProcessingMassTransactions={isProcessingMassTransactions}
                borderColor={borderColor}
              />
            ))}
          </div>
        </div>
      )
    }

    return <EmptyState title={emtpyTitle} />
  }

  return (
    <>
      <h1 className="text-2xl text-[#a9ffcc] text-center mb-2">{title}</h1>
      <div className='bg-gradient-to-r from-[#000000ff] via-[#4d4d4dff] to-[#000000ff] h-[6px] mb-2 border border-[#a9ffcc50] rounded-sm'></div>

      <Body />
    </>
  )
}

NFTListing.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  stakeNft: PropTypes.func,
  unstakeNft: PropTypes.func,
  emtpyTitle: PropTypes.string,
}

NFTListing.defaultProps = {
  items: [],
  stakeNft: () => { },
  unstakeNft: () => { },
  emtpyTitle: 'Nothing here...'
}

export default NFTListing
