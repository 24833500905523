import { useState } from "react";
import MainButton from '../Button'
import Countdown from 'react-countdown'

const NFTCard = ({ item, stakeNft, unstakeNft, isProcessingMassTransactions, borderColor }) => {
  const [isLoading, setIsLoading] = useState(false)

  const stake = async () => {
    setIsLoading(true)
    await stakeNft(item.mint)
    setIsLoading(false)
  }

  const unstake = async () => {
    setIsLoading(true)
    await unstakeNft(item.mint)
    setIsLoading(false)
  }

  const Button = () => {
    if (item.stakingProperties) {
      return (
        <MainButton
          title='Unvape'
          onClick={unstake}
          loading={isLoading || isProcessingMassTransactions}
        />
      )
    }

    return (
      <MainButton
        title='Vape'
        onClick={stake}
        loading={isLoading || isProcessingMassTransactions}
      />
    )
  }

  return (
    <>
      <div className="border-4 rounded flex flex-col" style={{ borderColor }}>
        <img src={item.image} alt={item.name} className='rounded' />

        <div className="flex flex-col gap-2 p-2">
          <h3 className="text-white text-center">{item.name}</h3>
          {item.tribe && (
            <p className="text-[#999999] text-center">{item.tribe}</p>
          )}
          {item.stakingProperties && (
            <Countdown
              date={Date.parse(item.stakingProperties.staked_at)}
              overtime
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <>
                    <p className="text-white text-center">
                      Since:
                    </p>
                    <p className="text-white text-center">
                      {days > 0 && `${days}:`}{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </p>
                  </>
                )
              }}
            />
          )}
          <div className="flex justify-center">
            <Button />
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCard