import { Transaction } from '@solana/web3.js'
import StakingApi from '../network/Staking'
import BlockchainTransactionsApi from '../network/BlockchainTransactions'

class StakingHelper {
  constructor(loadStakedNFTs, connection, signAllTransactions, publicKey, toast) {
    this.loadStakedNFTs = loadStakedNFTs
    this.signAllTransactions = signAllTransactions
    this.connection = connection
    this.publicKey = publicKey
    this.toast = toast
  }

  async startStake(mintAddresses) {
    const apiData = await StakingApi.getStakeTransactionHash({ walletAddress: this.publicKey, mintAddresses, })
    if (apiData.error) { return this.toast.error(apiData.error.response.data.message) }
    return await this.signTransactionsAndSendToApi(apiData.transaction_hashes)
  }

  async startUnstake(mintAddresses) {
    const apiData = await StakingApi.getUnstakeTransactionHash({ mintAddresses })
    if (apiData.error) { return this.toast.error(apiData.error.response.data.message) }
    return await this.signTransactionsAndSendToApi(apiData.transaction_hashes)
  }

  async startClaimRewards() {
    const apiData = await StakingApi.getClaimRewardsHash({ walletAddress: this.publicKey })
    if (apiData.error) { return this.toast.error(apiData.error.response.data.message) }
    return await this.signTransactionsAndSendToApi([apiData.transaction_hash])
  }

  // functions
  async signTransactionsAndSendToApi(transactionHashes) {
    try {
      if (transactionHashes.length) {
        const transactions = transactionHashes.map(hash => {
          return Transaction.from(Buffer.from(hash, "base64"))
        })
        const signedTransactions = await this.signAllTransactions(transactions)

        await Promise.all(
          signedTransactions.map(async (signedTransaction) => {
            try {
              const transactionId = await this.connection.sendRawTransaction(signedTransaction.serialize())
              await this.forceCreateTransaction(transactionId)
            } catch (error) {
              console.log(error)
            }
          })
        )
        return this.loadStakedNFTs()
      } else {
        throw ('There was an error, plase try again !')
      }
    } catch (error) {
      this.toast.error(error)
    }
  }

  async forceCreateTransaction(transactionId) {
    let processed = false
    while (!processed) {
      await new Promise(r => setTimeout(r, 2000));

      try {
        let apiCall = await BlockchainTransactionsApi.create({ transactionId })
        processed = apiCall?.status !== 'pending'
      } catch (error) {
        processed = false
      }
    }
  }
}

export default StakingHelper