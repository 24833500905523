import Base from "./Base"

class BlockchainTransactions extends Base {
  async create({ transactionId }) {
    const data = await this.post('blockchain_transactions', {
      transaction_id: transactionId,
    })
    return data?.data

  }
}

const blockchainTransactions = new BlockchainTransactions()
export default blockchainTransactions
