import axios from 'axios';
import { SERVER_URL } from '../global.config'

class Base {
  async get(url, params) {
    try {
      const apiResponse = await axios.get(`${SERVER_URL}/${url}`, {
        params
      })
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async post(url, params) {
    try {
      const apiResponse = await axios.post(`${SERVER_URL}/${url}`, params)
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async postFormData(url, formData) {
    try {
      const apiResponse = await axios.post(
        `${SERVER_URL}/${url}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )
      return apiResponse
    } catch (error) {
      console.log(error)
      return { data: { success: false, error } }
    }
  }

  async put(url, params) {
    try {
      const apiResponse = await axios.put(`${SERVER_URL}/${url}`, params)
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async putFormData(url, formData) {
    try {
      const apiResponse = await axios.put(
        `${SERVER_URL}/${url}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )
      return apiResponse
    } catch (error) {
      console.log(error)
      return { data: { success: false, error } }
    }
  }

  async delete(url, params) {
    try {
      const apiResponse = await axios.delete(`${SERVER_URL}/${url}`, { params })
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }
}

export default Base