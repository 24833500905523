import Staking from './Staking'

const VibeTribeStaking = () => {
  const getColorForNfts = (nfts) => {
    // Count NFTs for each tribe
    const tribeCounts = [1, 2, 3, 4].map(tribe =>
      nfts.filter(nft => nft.tribe === `Tribe ${tribe}`).length
    );

    // Check if each tribe has at least 4 NFTs
    const hasRequiredNfts = tribeCounts.map(count => count >= 4);

    // Determine color based on the presence of required NFTs in each tribe
    if (hasRequiredNfts.every(has => has)) return '#94d5b6'; // All tribes have >= 4 NFTs
    if (hasRequiredNfts.slice(0, 3).every(has => has)) return '#d59494'; // Tribes 1, 2, 3 have >= 4 NFTs
    if (hasRequiredNfts.slice(0, 2).every(has => has)) return '#d4d594'; // Tribes 1, 2 have >= 4 NFTs
    if (hasRequiredNfts[0]) return '#94bbd5'; // Only Tribe 1 has >= 4 NFTs
    // Default color when none of the tribes have >= 4 NFTs
    return '#999999';
  };

  return (
    <Staking
      creatorsWallets={['G8MekLErbCe8NrcFcuuesg8GiSBBQQcQnvyJnnFRyxhx']}
      collectionSize={1600}
      collection='vibe_tribe'
      tiers={
        [
          { text: 'Tier II >> Tribe 1', color: '#94bbd5' },
          { text: 'Tier III >> Tribe 2', color: '#d4d594' },
          { text: 'Tier IV >> Tribe 3', color: '#d59494' },
          { text: 'Tier V >> Tribe 4', color: '#94d5b6' },
        ]}
      getColorForNfts={getColorForNfts}
    />
  )
}

export default VibeTribeStaking