import Staking from './Staking'

const PuffsterzStaking = () => {
  const getColorForNfts = (nfts) => {
    const count = nfts.length
    if (count >= 20) return '#d59494'
    if (count >= 12) return '#d4d594'
    if (count >= 5) return '#94bbd5'
    return '#999999'
  }

  return (
    <Staking
      creatorsWallets={[
        '5tHfEV2stXJm56wyRKuYQCuqWLjMeT1jK32XmTr9PjTp',
        '7Emey6uWBFYuD8PASf5cbatpzBC4wbrQANjphVa6UjSY'
      ]}
      collectionSize={1888}
      collection='puffsterz'
      tiers={
        [
          { text: 'Tier I >> 1 - 4 NFTs', color: '#999999' },
          { text: 'Tier II >> 5 - 11 NFTs', color: '#94bbd5' },
          { text: 'Tier III >> 12 - 19 NFTs', color: '#d4d594' },
          { text: 'Tier IV >> 20+ NFTs', color: '#d59494' },
        ]}
      clubs={['PREMIUM', 'ANIMAL', 'DRAGON', 'PLATINUM', 'GOLD', 'STALLION']}
      getColorForNfts={getColorForNfts}
    />
  )
}

export default PuffsterzStaking
